import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { topics } from "../data/data";
import { generateProductURL } from "../../utils";
import "./SelectedTopic.scss";

const SelectedTopic = () => {
  const { topic } = useParams();
  const selectedTopic = topics.find(
    (t) => generateProductURL(t.name) === topic
  );
  const [shuffledTopics, setShuffledTopics] = useState(selectedTopic.ideas);
  const [completedIdeas, setCompletedIdeas] = useState([]);

  useEffect(() => {
    const shuffleTopics = () => {
      const shuffled = [...shuffledTopics].sort(() => Math.random() - 0.5);
      setShuffledTopics(shuffled);
    };

    shuffleTopics();
  }, []);

  const toggleIdeaCompletion = (index) => {
    const updatedCompleted = [...completedIdeas];
    updatedCompleted[index] = !updatedCompleted[index];
    setCompletedIdeas(updatedCompleted);
  };

  return (
    <section
      className={`w-full h-screen SelectedTopic_section`}
      style={{
        backgroundImage: `url(${selectedTopic?.img})`, // Truyền selectedTopic.img vào backgroundImage
      }}
    >
      <div className="bg-white p-2 backdrop-blur-md bg-opacity-5 w-full h-full">
        <h2 className="text-center text-3xl font-bold mb-4 uppercase mt-5 md:mt-10 text-white">
          TOPIC: {selectedTopic.name}
        </h2>
        <ul className="">
          {shuffledTopics.map((idea, index) => (
            <li key={index} className={`text-lg`}>
              <label
                className={`text-lg ${
                  completedIdeas[index] ? "line-through text-green-500" : "text-white"
                } flex items-center`}
              >
                <input
                  type="checkbox"
                  checked={completedIdeas[index]}
                  onChange={() => toggleIdeaCompletion(index)}
                  className="mr-2"
                />
                {idea}
              </label>
              {completedIdeas[index] && (
                <div className=" no-underline">Đã hoàn thành</div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SelectedTopic;
