import { configureStore } from "@reduxjs/toolkit";
// import GuideReducer from "./features/GuideSlice";
// 

const store = configureStore({
  reducer: {
    // guide: GuideReducer,
  },
});

export default store;
