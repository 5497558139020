import React from "react";
// import ErrorSvg from "./error_svg.svg";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  const backToHomePage = () => {
    navigate("/");
  };
  return (
    <section
      //   className="h-screen overflow-hidden flex items-center justify-center"
      className="ErrorPage_wrapper"
      style={{ background: "#edf2f7" }}
    >
      <div className="h-screen w-screen bg-gray-100 flex items-center">
        <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
          <div className="max-w-md">
            <div className="text-5xl font-dark font-bold">404</div>
            <p className="text-2xl md:text-3xl font-light leading-normal">
              Chúng tôi không thể tìm thấy trang này.
            </p>
            <p className="mb-8">
              Nhưng đừng lo lắng, bạn có thể tìm thấy nhiều thứ khác trên trang chủ của chúng tôi.
            </p>

            <button
              onClick={backToHomePage}
              className="capitalize px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700"
            >
              Trở về trang chủ
            </button>
          </div>
          <div className="max-w-lg">
            {/* <svg
              id="Layer_1"
              data-name="Layer 1"
              width="400"
              height="1800"
              viewBox="0 0 2395 1800"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1073.3 1016.93C1029.55 944.491 953.67 920.451 928.74 913.731C921.935 894.967 919.874 874.813 922.74 855.061C928.39 816.251 937.61 753.171 938.51 748.561L750 821.891L558.27 886.311C561.91 889.311 609.39 931.821 638.58 958.001C653.442 971.327 664.778 988.123 671.58 1006.89C656.74 1028.02 613.859 1095.08 626.659 1178.73C638.749 1257.73 693.82 1307.73 730.49 1341.12C756.963 1365.14 786.565 1385.47 818.49 1401.56C832.555 1411.65 848.612 1418.61 865.587 1421.99C882.562 1425.37 900.063 1425.09 916.919 1421.16C922.679 1419.82 933.76 1416.98 944.14 1413.78C948.72 1412.36 954.54 1410.55 961.2 1408.21L962.299 1407.8L963.4 1407.41C970.01 1404.94 975.64 1402.61 980.07 1400.76C990.07 1396.57 1000.42 1391.65 1005.7 1388.99C1021.15 1381.21 1034.77 1370.22 1045.63 1356.75C1056.5 1343.28 1064.36 1327.65 1068.7 1310.9C1082.91 1278.1 1092.61 1243.53 1097.55 1208.13C1104.37 1159.06 1114.61 1085.35 1073.3 1016.93Z"
                fill="#D6B49A"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M758.725 846.068C863.733 808.035 944.135 764.162 938.308 748.075C932.482 731.988 842.633 749.778 737.625 787.811C632.618 825.844 552.216 869.717 558.042 885.804C563.869 901.891 653.718 884.101 758.725 846.068Z"
                fill="#020202"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M959 1447L958.91 1529.82C958.91 1536.01 965.57 1541.04 973.79 1541.05H974.09C982.31 1541.05 988.99 1536.05 988.99 1529.85L989.08 1447.95C989.08 1447.42 982.13 1446.95 973.69 1446.95H959"
                fill="#818181"
              />
              <path
                d="M959 1447L958.91 1529.82C958.91 1536.01 965.57 1541.04 973.79 1541.05H974.09C982.31 1541.05 988.99 1536.05 988.99 1529.85L989.08 1447.95C989.08 1447.42 982.13 1446.95 973.69 1446.95H959"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1749 1447L1748.91 1529.82C1748.91 1536.01 1755.57 1541.04 1763.79 1541.05H1764.09C1772.31 1541.05 1778.99 1536.05 1778.99 1529.85L1779.08 1447.95C1779.08 1447.42 1772.08 1446.95 1763.69 1446.95H1749"
                fill="#818181"
              />
              <path
                d="M1749 1447L1748.91 1529.82C1748.91 1536.01 1755.57 1541.04 1763.79 1541.05H1764.09C1772.31 1541.05 1778.99 1536.05 1778.99 1529.85L1779.08 1447.95C1779.08 1447.42 1772.08 1446.95 1763.69 1446.95H1749"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1825.5 1426.5H755.25C752.399 1426.5 749.665 1427.63 747.649 1429.65C745.633 1431.66 744.5 1434.4 744.5 1437.25C744.5 1440.1 745.633 1442.84 747.649 1444.85C749.665 1446.87 752.399 1448 755.25 1448H1815C1816.43 1448 1817.85 1447.71 1819.17 1447.16C1820.49 1446.6 1821.68 1445.78 1822.68 1444.76C1823.69 1443.74 1824.47 1442.52 1825 1441.19C1825.52 1439.86 1825.77 1438.43 1825.74 1437L1825.5 1426.5Z"
                fill="#DCDBDA"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M701.74 867.5C701.74 867.5 663.121 1015 669.561 1076.79C673.401 1113.67 672.201 1174.79 670.561 1218.19C670.053 1231.85 664.225 1244.78 654.321 1254.2C644.416 1263.63 631.219 1268.81 617.546 1268.64C603.874 1268.47 590.81 1262.97 581.142 1253.3C571.474 1243.63 565.968 1230.56 565.8 1216.89C565.53 1194.89 563.02 1178.15 565.3 1165.69C578.97 1090.88 558.031 1089.69 570.381 1021.43C572.494 1014.04 574.681 1006.61 576.941 999.14C588.761 960.14 601.711 923.89 615.441 888.53C630.181 887.14 646.64 882.76 664.37 878.8C678 875.76 690.47 871.22 701.74 867.5Z"
                fill="#4EA7F1"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M719.77 1182.37C710.85 1182.37 704.32 1169.44 701.77 1164.37C684.18 1129.54 710.77 1068.78 721.09 1047.21C730.95 1069.41 755.41 1129.67 737.83 1164.37C735.17 1169.52 728.66 1182.37 719.77 1182.37Z"
                fill="#4EA7F1"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1915.78 1027C1805.03 931.17 1667.78 952.47 1647.99 955.87C1457.47 986.28 1303.37 1055.87 1279.78 1144.16C1274.93 1162.39 1271.03 1180.86 1268.08 1199.49C1220.93 1191.49 1141.79 1187.57 1095.7 1237.71L1095.47 1237.97C1082.38 1252.29 1091.56 1275.43 1110.86 1277.44C1122.42 1278.64 1136.31 1279.8 1151.97 1280.56C1184.48 1282.14 1254.06 1332.56 1297.63 1366.07C1324.81 1397.12 1363.17 1416.15 1404.34 1419H1405C1417.09 1427.11 1449 1446.11 1493.17 1445.43C1516.47 1445.03 1539.37 1439.3 1560.12 1428.7L1720.5 1426.5C1794.74 1448.05 1854.35 1445.8 1890.68 1441.25C1942.89 1434.72 1962.49 1421.68 1971.26 1414.47C2001.56 1389.47 2012.59 1350.53 2020.39 1311.54C2036.41 1231.43 2010.61 1109.06 1915.78 1027Z"
                fill="#F8F3ED"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1267 1198C1257.62 1170.45 1243.34 1118.22 1242.12 1068.85C1241.22 1032.18 1245.44 995.563 1254.67 960.061C1228.42 906.119 1217.15 846.111 1222.05 786.321C1222.31 783.418 1223.3 780.63 1224.94 778.221C1226.58 775.812 1228.81 773.863 1231.42 772.56C1234.03 771.257 1236.92 770.642 1239.83 770.775C1242.74 770.909 1245.57 771.785 1248.05 773.321L1380.15 855.431C1429.31 842.075 1480.95 840.642 1530.78 851.251L1650.59 753.251C1652.47 751.71 1654.72 750.695 1657.12 750.31C1659.52 749.925 1661.98 750.184 1664.24 751.059C1666.51 751.934 1668.5 753.396 1670.02 755.293C1671.54 757.191 1672.52 759.457 1672.88 761.861C1682.63 829.713 1676.96 898.888 1656.31 964.251C1665.49 992.377 1667.94 1022.27 1663.45 1051.51"
                fill="#F8F3ED"
              />
              <path
                d="M1267 1198C1257.62 1170.45 1243.34 1118.22 1242.12 1068.85C1241.22 1032.18 1245.44 995.563 1254.67 960.061C1228.42 906.119 1217.15 846.111 1222.05 786.321C1222.31 783.418 1223.3 780.63 1224.94 778.221C1226.58 775.812 1228.81 773.863 1231.42 772.56C1234.03 771.257 1236.92 770.642 1239.83 770.775C1242.74 770.909 1245.57 771.785 1248.05 773.321L1380.15 855.431C1429.31 842.075 1480.95 840.642 1530.78 851.251L1650.59 753.251C1652.47 751.71 1654.72 750.695 1657.12 750.31C1659.52 749.925 1661.98 750.184 1664.24 751.059C1666.51 751.934 1668.5 753.396 1670.02 755.293C1671.54 757.191 1672.52 759.457 1672.88 761.861C1682.63 829.713 1676.96 898.888 1656.31 964.251C1665.49 992.377 1667.94 1022.27 1663.45 1051.51"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M583.29 1375.5H583C583 1375.5 574.5 1375.39 566.56 1367.77C560.31 1361.77 565.71 1335.34 584.56 1304.69C600.66 1335.83 604.64 1361.82 598.72 1367.77C591.12 1375.46 583.29 1375.5 583.29 1375.5Z"
                fill="#4EA7F1"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M2024.5 1260.5C2039.31 1267.32 2062.74 1280.91 2078.5 1306.5C2114.92 1365.65 2087.78 1452.26 2037.13 1497.83C2000.37 1530.91 1958.04 1536.11 1924.74 1540.4C1905.22 1542.91 1814.74 1554.18 1752.6 1497.83C1740.03 1486.43 1710.6 1459.72 1714.94 1426.7C1717.19 1409.7 1728.73 1387.01 1748.41 1380.7C1786.12 1368.56 1808.69 1430.87 1879.5 1438.53C1889.7 1439.63 1933.38 1443.11 1967.5 1415.53C1973.09 1411.01 1982.31 1402.53 1993.5 1383.53C2005 1364 2024.43 1323.52 2024.5 1260.5Z"
                fill="#333333"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1560.5 1428.5C1560.5 1428.5 1629.5 1396.5 1645.5 1334.5"
                stroke="#191818"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1530.83 851.27L1650.64 753.27C1652.52 751.73 1654.77 750.715 1657.17 750.33C1659.57 749.945 1662.03 750.203 1664.29 751.079C1666.56 751.954 1668.55 753.415 1670.07 755.313C1671.59 757.211 1672.58 759.477 1672.93 761.88C1676.17 784.46 1677.06 807.34 1677.28 842.88C1677.5 878.42 1665 911 1656.5 964.5C1627.04 914.901 1583.22 875.415 1530.83 851.27Z"
                fill="#333333"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1408.5 1420.5C1406.73 1418.96 1399.67 1412.5 1399.5 1402.83C1399.39 1394.91 1404.02 1389.27 1405.5 1387.5C1417.68 1372.66 1439.32 1379.15 1464.5 1372.5C1476.41 1369.35 1492.86 1362.28 1510.5 1344.5"
                stroke="#191818"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1452.5 1115.5C1537 1115.5 1605.5 1063.12 1605.5 998.5C1605.5 933.883 1537 881.5 1452.5 881.5C1368 881.5 1299.5 933.883 1299.5 998.5C1299.5 1063.12 1368 1115.5 1452.5 1115.5Z"
                fill="#333333"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1355 1022.5C1372.4 1022.5 1386.5 1008.4 1386.5 991C1386.5 973.603 1372.4 959.5 1355 959.5C1337.6 959.5 1323.5 973.603 1323.5 991C1323.5 1008.4 1337.6 1022.5 1355 1022.5Z"
                fill="#F8F59C"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1672.5 762.5C1672.5 762.5 1602.5 857.5 1595.5 879.5C1590.26 895.95 1614.12 887.8 1626.5 882.64C1627.04 882.419 1627.63 882.365 1628.19 882.485C1628.76 882.606 1629.28 882.895 1629.68 883.315C1630.07 883.735 1630.34 884.266 1630.43 884.838C1630.52 885.41 1630.44 885.996 1630.19 886.52L1621.89 904.05C1621.36 905.175 1621.17 906.431 1621.34 907.663C1621.52 908.895 1622.05 910.048 1622.88 910.98C1623.7 911.911 1624.79 912.579 1625.99 912.901C1627.19 913.222 1628.46 913.184 1629.64 912.79L1639.55 909.49C1640.07 909.314 1640.64 909.293 1641.17 909.43C1641.71 909.566 1642.19 909.853 1642.57 910.257C1642.94 910.661 1643.19 911.165 1643.29 911.708C1643.39 912.252 1643.32 912.811 1643.11 913.32L1639.52 930.5L1656.52 964.5C1676.89 899.209 1682.35 830.183 1672.52 762.5H1672.5Z"
                fill="#F3D2C9"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1379.5 855.5C1335.64 828.31 1290.15 799.4 1246.29 772.21C1237.22 766.59 1222.63 773.83 1222.5 784.5C1222.23 807.31 1218.5 832.6 1225.5 867.5C1229.27 886.34 1230.95 896.08 1234.76 909C1239.88 926.37 1246.48 943.266 1254.5 959.5C1259.66 949.336 1265.68 939.634 1272.5 930.5C1278.25 922.79 1299.06 896.08 1336.5 874.5C1350.12 866.663 1364.53 860.293 1379.5 855.5Z"
                fill="#333333"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1222.5 782.5C1222.5 782.5 1297.88 848.44 1307.21 865.71C1307.76 866.71 1310.1 871.33 1308.37 873.42C1305.07 877.42 1290.96 867.34 1284.5 872.5C1283.84 873.033 1283.3 873.685 1282.88 874.42C1282.15 875.785 1281.84 877.331 1281.97 878.871C1282.1 880.412 1282.68 881.88 1283.63 883.1C1285.79 885.97 1288.63 890.57 1288.36 894.94C1288.36 896.239 1287.96 897.507 1287.21 898.57C1285.28 900.93 1281.69 900.95 1280.7 900.95C1274.15 900.95 1270.61 894.64 1270.45 894.35C1269.6 893.659 1268.52 893.294 1267.43 893.318C1266.33 893.341 1265.27 893.753 1264.45 894.48C1263.93 895.028 1263.6 895.729 1263.51 896.48C1263.28 898.211 1263.56 899.971 1264.33 901.54C1266.5 905.93 1263.96 920.09 1262.48 926.47C1260.24 935.989 1256.53 945.099 1251.48 953.47C1242.48 933.81 1230.33 902.47 1224.48 864.47C1221.3 843.717 1220.14 822.707 1220.99 801.73C1221.37 793.9 1222 787.3 1222.5 782.5Z"
                fill="#F3D2C9"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1355 1013.5C1367.43 1013.5 1377.5 1003.43 1377.5 991C1377.5 978.574 1367.43 968.5 1355 968.5C1342.57 968.5 1332.5 978.574 1332.5 991C1332.5 1003.43 1342.57 1013.5 1355 1013.5Z"
                fill="black"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1557 1022.5C1574.4 1022.5 1588.5 1008.4 1588.5 991C1588.5 973.603 1574.4 959.5 1557 959.5C1539.6 959.5 1525.5 973.603 1525.5 991C1525.5 1008.4 1539.6 1022.5 1557 1022.5Z"
                fill="#F8F59C"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1557 1013.5C1569.43 1013.5 1579.5 1003.43 1579.5 991C1579.5 978.574 1569.43 968.5 1557 968.5C1544.57 968.5 1534.5 978.574 1534.5 991C1534.5 1003.43 1544.57 1013.5 1557 1013.5Z"
                fill="black"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1445.26 997.131L1455.5 998.501L1464.89 997.161C1465.3 997.102 1465.71 997.161 1466.08 997.331C1466.46 997.501 1466.77 997.774 1467 998.119C1467.22 998.463 1467.34 998.864 1467.34 999.274C1467.34 999.684 1467.22 1000.09 1467 1000.43L1457.91 1014.71C1457.64 1015.12 1457.28 1015.45 1456.85 1015.68C1456.42 1015.91 1455.95 1016.04 1455.46 1016.05C1454.98 1016.06 1454.49 1015.95 1454.06 1015.73C1453.63 1015.51 1453.25 1015.19 1452.97 1014.79L1443.2 1000.46C1442.97 1000.12 1442.84 999.716 1442.84 999.303C1442.83 998.89 1442.94 998.483 1443.16 998.132C1443.37 997.781 1443.69 997.5 1444.06 997.322C1444.43 997.145 1444.85 997.078 1445.26 997.131Z"
                fill="#F3D2C9"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1454.74 1016.08C1454.74 1016.08 1457.5 1033.5 1437.5 1031.5"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1455.63 1017.08C1455.63 1017.08 1452.87 1034.5 1472.87 1032.5"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M1664.5 1001.5L1735 980Z" fill="#F8F3ED" />
              <path
                d="M1664.5 1001.5L1735 980"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M1667 1017L1733.5 1027.5Z" fill="#F8F3ED" />
              <path
                d="M1667 1017L1733.5 1027.5"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M1244 1017L1183.5 1030.5Z" fill="#F8F3ED" />
              <path
                d="M1244 1017L1183.5 1030.5"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M1246.5 1000.5L1180 990Z" fill="#F8F3ED" />
              <path
                d="M1246.5 1000.5L1180 990"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M497.79 404C542.36 424.37 593.09 470.11 653.5 528.48C746.29 618.14 804.3 762.91 822.5 817.48C816.73 820.16 792.27 774.8 786.5 777.48C767.23 724.74 729.23 638.63 647.5 554.48C580.7 485.7 522.5 434.81 475.5 412.48L497.79 404Z"
                fill="#8BB174"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M745.55 850.159C670.87 787.159 566.29 710.669 531.41 697.269C441.63 662.769 361.93 647.719 310.32 647.209C315.867 641.516 321.424 635.829 326.99 630.149C375.99 629.929 446.6 643.539 525.99 671.149C557.83 682.239 679.71 761.629 766.99 841.799L745.55 850.159Z"
                fill="#8BB174"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M823.54 819.3C805.78 795.4 763.98 722.16 739.62 698.53C690.972 651.438 634.669 612.963 573.12 584.75L550.81 593.19C619.065 625.738 680.959 670.213 733.58 724.52C751.28 742.81 788.02 810.29 802 828.52L823.54 819.3Z"
                fill="#8BB174"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1479.5 1367.5L1513.5 1443.5C1496.53 1445.43 1479.38 1445.09 1462.5 1442.5C1462.5 1442.5 1433.31 1439.11 1413.91 1424.5C1400.43 1414.38 1399.79 1407.25 1399.62 1405.12C1398.84 1395.38 1405.26 1388.49 1407.75 1386.12L1408.5 1385.44C1417.5 1377.58 1433.5 1376.51 1434.5 1376.44C1444.74 1375.81 1458.89 1373.16 1479.5 1367.5Z"
                fill="#333333"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M1173.28 1285.23L1203.5 1195.5C1182.97 1195.2 1162.59 1198.94 1143.5 1206.5C1129.76 1212.24 1116.95 1219.99 1105.5 1229.5C1104.5 1230.35 1090.5 1242.38 1090 1253.97C1090 1254.6 1090 1255.19 1090 1255.23C1090.23 1265 1097.33 1271.23 1100.06 1273.23L1100.88 1273.83C1109.25 1279.75 1119.46 1279.09 1134.51 1279.46C1143 1279.67 1147.24 1279.78 1152.51 1280.46C1159.57 1281.39 1166.52 1282.99 1173.28 1285.23Z"
                fill="#333333"
                stroke="#191818"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M292.3 344.49L264.25 329.19C257.256 325.374 251.548 319.574 247.847 312.52C244.145 305.465 242.615 297.472 243.45 289.55L245.8 267.34C246.74 258.437 249.603 249.845 254.19 242.159C258.778 234.472 264.981 227.873 272.37 222.82C276.654 219.891 281.636 218.143 286.812 217.753C291.987 217.364 297.174 218.345 301.85 220.6C304.547 221.855 307.311 222.964 310.13 223.92C342.706 235.433 372.338 253.998 396.91 278.29L353.44 357.12L292.3 344.49Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M318.73 318L318.04 318.05C309.25 318.691 300.901 322.151 294.235 327.916C287.569 333.681 282.941 341.444 281.04 350.05L278.36 362.17C275.585 374.723 277.416 387.855 283.521 399.17C289.625 410.485 299.595 419.226 311.61 423.8L394.1 455.2L406.8 365L349.8 326.31C340.676 320.117 329.727 317.188 318.73 318Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M465 262.819L432.87 220.229C426.739 212.09 418.416 205.865 408.876 202.283C399.337 198.7 388.974 197.908 379 199.999L368.47 202.209C363.377 203.27 358.624 205.573 354.636 208.914C350.647 212.255 347.546 216.53 345.608 221.358C343.67 226.187 342.956 231.42 343.529 236.591C344.101 241.762 345.943 246.712 348.89 250.999L375.89 289.299L460.5 319.909L465 262.819Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M395.47 400.311C431.44 400.311 460.6 371.151 460.6 335.181C460.6 299.21 431.44 270.051 395.47 270.051C359.5 270.051 330.34 299.21 330.34 335.181C330.34 371.151 359.5 400.311 395.47 400.311Z"
                fill="#F8F59C"
                stroke="#191818"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M410.35 262.799L407.17 287.229C405.9 296.939 408.22 306.149 413.67 313.049L457.33 368.329L482.93 435.119C488.217 426.088 492.743 416.632 496.46 406.849C496.46 406.849 506.12 379.669 505.01 349.239C503.01 292.759 463.16 247.829 456.5 240.499C453.66 237.067 449.811 234.618 445.5 233.499C437.18 231.499 430.27 235.909 426.68 238.189C414.7 245.799 411.24 258.849 410.35 262.799Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M393 455.329L343.6 432.499C333.483 427.809 325.613 419.332 321.686 408.895C317.758 398.458 318.086 386.896 322.6 376.699L332.87 353.519C338.607 340.577 349.033 330.287 362.05 324.721C375.067 319.155 389.709 318.725 403.03 323.519L462.21 344.869C474.022 349.142 484.001 357.355 490.463 368.126C496.926 378.897 499.479 391.566 497.69 403.999C495.014 416.724 488.966 428.494 480.18 438.079C457.44 462.429 425.07 461.079 419.31 460.799C410.303 460.401 401.419 458.554 393 455.329Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M220.48 538.451L216.38 524.301C213.798 515.384 214.408 505.845 218.107 497.331C221.806 488.816 228.36 481.859 236.64 477.661C244.054 473.91 252.365 472.297 260.643 473.004C268.922 473.71 276.84 476.708 283.51 481.661C296.1 485.881 353.06 506.481 382.32 566.151C392.465 587.005 398.007 609.797 398.57 632.981C398.596 634.461 398.224 635.92 397.492 637.207C396.761 638.494 395.697 639.56 394.413 640.295C393.128 641.03 391.669 641.406 390.189 641.384C388.71 641.363 387.263 640.943 386 640.171L220.48 538.451Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M173.88 677.25L191 690C196.106 693.791 201.612 697.01 207.42 699.6C214.386 702.672 221.544 705.287 228.85 707.43C244.66 712.07 283.66 723.491 327.03 707.531C360.29 695.291 380.96 672.53 391.74 657.67C392.483 656.678 392.949 655.506 393.09 654.275C393.232 653.043 393.044 651.796 392.546 650.661C392.047 649.526 391.256 648.543 390.254 647.814C389.251 647.085 388.073 646.635 386.84 646.51L198.54 625.16C191.884 624.406 185.156 625.703 179.258 628.879C173.36 632.055 168.573 636.958 165.54 642.93C162.661 648.716 161.919 655.333 163.445 661.613C164.971 667.892 168.667 673.431 173.88 677.25Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M160.14 576.001C162.278 584.96 166.326 593.351 172.008 600.6C177.69 607.849 184.871 613.785 193.06 618.001L250.48 647.551C254.33 649.061 259.96 651.161 266.85 653.371C281.519 658.138 296.576 661.618 311.85 663.771C339.12 667.011 369.21 658.411 386.29 652.361C387.937 651.776 389.453 650.873 390.751 649.701C392.049 648.53 393.102 647.114 393.852 645.535C394.602 643.956 395.032 642.244 395.119 640.499C395.207 638.753 394.949 637.007 394.36 635.361C384.14 607.071 369.08 590.781 360.59 582.901C344.91 568.351 325.88 558.641 310.67 550.901C301.041 545.985 291.164 541.569 281.08 537.671L232.18 524.161C216.005 519.72 198.732 521.818 184.09 530.001L179.18 532.741C171.735 536.902 165.849 543.376 162.414 551.182C158.978 558.988 158.18 567.701 160.14 576.001Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M525.79 497.881C524.302 497.854 522.828 498.155 521.47 498.763C520.112 499.371 518.906 500.271 517.936 501.399C516.965 502.527 516.256 503.854 515.857 505.287C515.459 506.721 515.381 508.224 515.63 509.691C519.63 533.371 529.81 585.611 543.97 598.811C562.44 616.031 592.12 715.181 674.67 694.271C731.35 679.911 713.93 620.751 697.43 585.051C688.291 565.209 673.78 548.321 655.54 536.301C637.42 524.471 617.709 515.279 597 509.001C573.894 502.006 549.927 498.263 525.79 497.881Z"
                fill="#DA4E22"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M857.63 805C860.5 803.5 830.5 512.5 746.5 400.5C662.5 288.5 642.5 270.5 642.5 270.5C642.5 270.5 640.5 355.5 676.5 415.5C712.5 475.5 754.5 575.5 766.5 597.5C778.5 619.5 822.5 820.5 822.5 820.5L857.63 805Z"
                fill="#8BB174"
                stroke="black"
                stroke-width="3"
                stroke-miterlimit="10"
              />
            </svg> */}
            {/* <img src={ErrorSvg} alt="Your SVG" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
