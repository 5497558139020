import hotel from '../../assets/images/hotel.jpg'
import zoo from '../../assets/images/zoo.jpg'
import coffee from '../../assets/images/coffee.jpg'
import consumer_age from '../../assets/images/consumer_age.jpg'
import early_australia from '../../assets/images/early_australia.webp'
import charles_dickens from '../../assets/images/charles_dickens.webp'
import tulips from '../../assets/images/tulips.jpg'
import eating_in_china from '../../assets/images/eating_in_china.jpg'
import children_and_exercises from '../../assets/images/children_and_exercises.jpg'
import antarctica_frozen_land from '../../assets/images/antarctica_frozen_land.jpg'
import doggett_coat_and_badge from '../../assets/images/doggett_coat_and_badge.jpg'
import meatless_diet from '../../assets/images/meatless_diet.png'
import music from '../../assets/images/music.jpg'

export const topics = [
  {
    id: 1,
    name: "Hotel",
    img: hotel,
    ideas: [
      "A sensible financial choice",
      "The price of convenience may be high",
      "The importance of planning in advance",
      "The impact of lack of freedom",
      "The benefits of living with less",
      "The advantages of having your own space",
      "A competitive business",
    ],
  },
  {
    id: 2,
    name: "Zoo",
    img: zoo,
    ideas: [
      "Symbol of privilege and wealth",
      "Opening the door for everyone",
      "Away from amusement towards instruction",
      "Away from enclosure towards greater freedom",
      "A different set of values",
      "A new mission of conversation",
      "A modern day alternative",
    ],
  },
  {
    id: 3,
    name: "Coffee",
    img: coffee,
    ideas: [
      "The custom of coffee drinking begins to spread",
      "Coffee encourages",
      "A habit that has become a big economy",
      "Problems of coffee economy",
      "A remedy of unjust revenue distribution",
      "Health risks versus health benefits debate",
      "The ancient origin of coffee",
    ],
  },
  {
    id: 4,
    name: "Consumer age",
    img: consumer_age,
    ideas: [
      "Making things last longer",
      "A temporary experiment",
      "The reason of secrecy",
      "Still relevant to our times",
      "The difficulty of being generous",
      "Reason to reach a compromise",
      "Important lessons for all of us",
    ],
  },
  {
    id: 5,
    name: "Early Australia",
    img: early_australia,
    ideas: [
      "An alternative history of settlement",
      "Natural barrier to resettlement",
      "Technology helps uncover the ocean's secret",
      "A journey made by stages",
      "New evidence that leads to speculation",
      "Lack of knowledge and skills",
      "Determination of the explorers through the ages",
    ],
  },
  {
    id: 6,
    name: "Charles Dickens",
    img: charles_dickens,
    ideas: [
      "Dickens for our time",
      "Difficulties for modern readers",
      "Keeping the readers guessing",
      "The influence of media",
      "Dickens's early success",
      "Trying to protect his property",
      "Bring the books to life",
    ],
  },
  {
    id: 7,
    name: "Tulips",
    img: tulips,
    ideas: [
      "The economy during the Golden Age",
      "Coming into fashion",
      "An object of trade",
      "Different types of tulip",
      "Trade mechanics",
      "Trade across Europe",
      "An unexpected turn of events",
    ],
  },
  {
    id: 8,
    name: "Eating in China",
    img: eating_in_china,
    ideas: [
      "The origins of Chinese food",
      "The influence of philosophy",
      "Regional variations",
      "Cooking methods",
      "The style of eating",
      "Changes in the Chinese diet",
      "Effects of a changing diet",
    ],
  },
  {
    id: 9,
    name: "Children and Exercises",
    img: children_and_exercises,
    ideas: [
      "Factors contributing to inactivity",
      "The situation has the potential of being worse",
      "The success of a simple idea",
      "The wider effects of regular activity",
      "Ways in which environment can influence behavior",
      "A design for exercise and for study",
      "Achieving the right balance",
    ],
  },
  {
    id: 10,
    name: "Antarctica - Frozen Land",
    img: antarctica_frozen_land,
    ideas: [
      "Who is in charge?",
      "First step on the ice",
      "Where is the end of the Earth?",
      "Hidden geography",
      "Race to the Pole",
      "Less effort needed",
      "Why is it so cold?",
    ],
  },
  {
    id: 11,
    name: "Doggett's Coat and Badge",
    img: doggett_coat_and_badge,
    ideas: [
      "The easiest way to travel",
      "Result of a lucky escape",
      "Origins of what the winner receives",
      "A need for change",
      "Earning a reputation",
      "Generations of champions",
      "Not in it for the money",
    ],
  },
  {
    id: 12,
    name: "Meatless Diet",
    img: meatless_diet,
    ideas: [
      "Types of vegetarian",
      "Various explanations",
      "Possible to happen",
      "Farming factory - it is a harmful thing",
      "Respect the life",
      "Health gets better with diet",
      "Our responsibilities with the global environment",
    ],
  },
  {
    id: 13,
    name: "Music",
    img: music,
    ideas: [
      "A physically demanding activity",
      "A good way to boost your memory",
      "A great opportunity to broaden your social circle",
      "A way to learn discipline and the importance of routine",
      "A creative outlet for expressing emotions",
      "Enhanced sensitivity to other people's feelings",
      "Develop a greater sense of well-being",
    ],
  },
];
