import React, { useState } from "react";
import { topics } from "../data/data";
import { Link, useNavigate } from "react-router-dom";
import { generateProductURL } from "../../utils";

const HomePage = () => {
  const navigate = useNavigate();
  const [shuffledTopics, setShuffledTopics] = useState(topics);
  const [selectedTopic, setSelectedTopic] = useState(null);
  // Function to shuffle topics
  const shuffleTopics = () => {
    const shuffled = [...shuffledTopics].sort(() => Math.random() - 0.5);
    setShuffledTopics(shuffled);
  };

  console.log("selectedTopic", selectedTopic);

  const selectRandomTopic = () => {
    const randomIndex = Math.floor(Math.random() * topics.length);
    setSelectedTopic(topics[randomIndex]);
    navigate(`/${generateProductURL(topics[randomIndex].name)}`)
  };

  return (
    <section className="p-4 w-full h-full">
      {/* Title */}
      <div className="text-center text-3xl font-bold mb-4 uppercase mt-5 md:mt-10">
        Học đi ko bán vé số đó
      </div>

      {/* Shuffle Button */}
      <div className="flex justify-end mb-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={selectRandomTopic}
        >
          Tự Chọn Topic
        </button>
      </div>

      {/* Topics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {shuffledTopics.map((topic) => (
          <div
            key={topic.id}
            className="relative p-4 border rounded shadow-md flex flex-col justify-around items-center text-center font-medium w-full h-80"
          > 
            <img src={topic.img} alt={topic.name} className="absolute w-full h-full object-cover" />
            {/* Topic Name */}
            <div className="font-bold mb-2 text-3xl uppercase z-10 text-rblack bg-white backdrop-blur-md rounded-lg px-2">
              Topic: {topic.name}
            </div>

            <Link
            className="border border-white text-white bg-black backdrop-blur-sm rounded-lg p-2 flex justify-center items-center text-center z-10"
            to={`/${generateProductURL(topic.name)}`} onClick={() => setSelectedTopic(topic)}>
              Chọn Topic này
            </Link>

            {/* Ideas List */}
            {/* <ul className="text-left list-disc list-inside">
              {topic.ideas.map((idea, index) => (
                <li key={index} className="text-sm">
                  {idea}
                </li>
              ))}
            </ul> */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default HomePage;
